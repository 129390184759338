import {Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {GRAPHQL_SOURCE, graphqlOperation} from '../constants';
import GetBackInStockSettingsDocument from '!!raw-loader!../graphql/getBackInStockSettings.graphql';

export type GetBackInStockSettingsQuery = {
  __typename?: 'WixStoresQueries';
  backInStock: {
    __typename?: 'BackInStockQueries';
    settings: {__typename?: 'BackInStockSettings'; isCollectingRequests: boolean};
  };
};

export async function getBackInStockSettings(siteStore: SiteStore): Promise<GetBackInStockSettingsQuery> {
  const url = siteStore.resolveAbsoluteUrl(`/${Topology.NODE_GRAPHQL_URL}`);
  const bodyData = {
    operationName: graphqlOperation.GetBackInStockSettings,
    query: minifyGraphqlQuery(GetBackInStockSettingsDocument as unknown as string),
    source: GRAPHQL_SOURCE,
  };

  const result = await siteStore.httpClient.get(
    url,
    {
      queryParams: bodyData,
    },
    false
  );

  return result.data;
}

function minifyGraphqlQuery(query: string): string {
  return query
    .replace(/\s+/g, ' ')
    .replace(/\s?{\s?/g, '{')
    .replace(/\s?}\s?/g, '}')
    .trim();
}
